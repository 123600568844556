import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider, dividerClasses, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, SelectChangeEvent, Skeleton, Tab, Tabs, TextField, Typography } from "@mui/material";
import { TableContainer, TableBody, TableCell, TableHead, TableRow, Chip, Table } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import { ApiDataListType, ApiDataResponseListLengthCheck, DocumentType, FileCategory, GetCollegeId, GetCurrentSemester, PAGE_SIZE } from "../../constants/options";
import VmButton from "../../components/shared-button";
import VmTable from "../../components/shared-table";
import VmModal from "../../components/shared-modal";
import Heading from "../../components/shared-heading";
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { INLINE_SELECTED_OPTION, INLINE_SELECTED_OPTION_MINI, INLINE_SELECT_OPTION, INLINE_SELECT_OPTION_MINI, ITEM_PERFECT_INLINED, TD_FIRST, TD_LAST, TD_NORMAL, THEME_HIGHLIGHT } from "../../constants/style";
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import TabPanel from "../../components/shared-tab-panel";
import { constructImgUrl } from "../../constants/settings";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import CollapsedSection from "../../components/shared-collapsed-section";

// slide icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HistoryIcon from '@mui/icons-material/History';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import TopicIcon from '@mui/icons-material/Topic';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import PendingIcon from '@mui/icons-material/Pending';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import SlideDown from "react-slidedown";
import VmVisitSlideDownCard from "../../components/shared-VisitSlideDownCard";
import TraineeshipCard from "../../components/shared-traineeship-card";

function TabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

enum TraineeListMode {
  ALL = 0,
  BY_GROUP = 1,
}

const TeacherProfilePage = observer(({ location }) => {
  const { studentStore, groupStore, teacherStore, userStore, fileStore } = useStores();
  const [teacherId, setTeacherId] = useState<string>(location.state && location.state.teacherId ? location.state.teacherId : "0");
  const [currentTeacher, setCurrentTeacher] = useState<any>();
  const [tabValue, setTabValue] = useState<TraineeListMode>(TraineeListMode.ALL);
  const [modalTabValue, setModalTabValue] = useState<number>(0);
  const [currentVisit, setCurrentVisit] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [yearDesc, setYearDesc] = useState<string>(new Date().getFullYear().toString());
  const [currentOrg, setCurrentOrg] = useState<number>(0);
  const [searchKey, setSearchKey] = useState<string>("");
  const [studentId, setStudentId] = useState<number>(0);
  const [yearSelectOptions, setYearSelectOptions] = useState<number[]>([]);
  const [traineeshipId, setTraineeshipId] = useState<number>(0);
  const [traineeshipList, setTraineeshipList] = useState<any>();
  const [traineeshipFold, setTraineeshipFold] = useState<number>(0);
  const [currentTraineeship, setCurrentTraineeship] = useState<any>();
  const [page, setPage] = useState<number>(1);

  //june folding const
  const [fold, setFold] = useState<number>(0);

  useEffect(() => {
    let currentYear = new Date().getFullYear();
    setYearSelectOptions([+currentYear - 2, +currentYear - 1, currentYear, +currentYear + 1, +currentYear + 2]);

    if (ApiDataResponseListLengthCheck(ApiDataListType.TEACHER) == 0 ||
      teacherStore.teacherProfileList.data.length != ApiDataResponseListLengthCheck(ApiDataListType.TEACHER)) {
      teacherStore.getTeacherProfileList(0, 0)
        .then(() => {
          if (location.state && location.state.teacherId) onSelectTeacher(location.state.teacherId);
        });
    } else {
      if (location.state && location.state.teacherId) onSelectTeacher(location.state.teacherId);
    }
  }, []);

  const onChangeTeacher = (event: SelectChangeEvent) => {
    let id = event.target.value;
    onSelectTeacher(id);
  };
  const onChangeYear = (event: SelectChangeEvent) => {
    setYearDesc(event.target.value as string);
  };

  const onSelectTeacher = (id: string) => {
    if (id === "0") setCurrentTeacher(null);
    else {
      setCurrentTeacher(teacherStore.teacherProfileList.data.find(t => t.id == +id));
      onSearchStudentList(tabValue, id);
      teacherStore.getTraineeListByTrainerIdAndYear(+id, yearDesc, PAGE_SIZE, 0);
    }
    setTeacherId(id);
    setStudentId(0);
    setCurrentOrg(0);
    setSearchKey("");
  }

  const onSearchStudentList = (displayMode: TraineeListMode, id?: number) => {
    if (displayMode == TraineeListMode.ALL) teacherStore.getTraineeListByTrainerIdAndYear(id ? id : +teacherId, yearDesc, PAGE_SIZE, 0);
    else {
      teacherStore.getTraineeListGroupByTrainerIdAndYear(id ? id : +teacherId, yearDesc)
        .then((res) => setCurrentOrg(res.length > 0 ? res[0].id : 0));
    }
  }

  const onRequestTraineeshipList = (traineeId: number) => {
    setStudentId(traineeId);
    setSearchKey("");
    setTraineeshipId(0);
    setCurrentTraineeship("");
    studentStore.getTraineeshipListByYearAndTraineeId(traineeId, yearDesc)
      .then((res) => setTraineeshipList(res));
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (+event.target.value >= 1) {
      setTabValue(TraineeListMode.BY_GROUP);
      onSearchStudentList(TraineeListMode.BY_GROUP)
    } else {
      setTabValue(TraineeListMode.ALL);
      onSearchStudentList(TraineeListMode.ALL);
    }
    setSearchKey("");
    setTraineeshipList(null);
    setStudentId(0)
  };

  const handleChangeModalTab = (event: React.SyntheticEvent, newValue: number) => {
    setModalTabValue(newValue);
  }

  const handleShowModal = (id: number,) => {
    let target = studentStore.visitListByTraineeship.find(v => v.id == id);
    setCurrentVisit(target);
    setShowModal(true);
  }

  const onToggleFold = (id: number) => {
    if (fold === id) {
      setFold(0)
    } else {
      var delayInMilliseconds = 500; //1 second
      userStore.setVisitTableLoading(true);
      setTimeout(function () {
        userStore.getChangeRequestListByVisitId(id);
      }, delayInMilliseconds);
      userStore.getChangeRequestListByVisitId(id);
      setFold(id)
      // onToggleChangeVisitList(id)
    }
  }

  const onToggleTraineeshipFold = (id: number) => {
    if (traineeshipFold === id) {
      setTraineeshipFold(0);
    } else {
      setTraineeshipFold(id);
    }
  };

  return (
    <ContentLayout
      pageName={t('TEACHER_PROFILE')}
      pageHeading=""
      breadCrumb={[
        { label: t('TEACHER_PROFILE') + t('SETTING_B'), to: "" },
        { label: t('OVERVIEW'), to: "/teacher" },
        { label: t('TEACHER_PROFILE'), to: "/teacher-profile" }
      ]}
      showHeadingButton={false}
    >
      <div className="mb-6" style={{ marginTop: -10 }}>
        <FormControl fullWidth sx={{ width: 300 }}>
          <InputLabel>{t('SELECT_TEACHER')}</InputLabel>
          <Select
            label={t('SELECT_TEACHER')}
            sx={{ width: 300, background: 'white' }}
            value={teacherId}
            onChange={onChangeTeacher}
          >
            <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
            {teacherStore.teacherProfileList.data.map((teacher: any, index: number) => (
              <MenuItem key={`teacher_select_${index}`} value={teacher.id}>
                {teacher.firstName + " " + teacher.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {teacherStore.loading ?
        <>
          <div className="text-center my-4"><Skeleton /></div>
          <div className="text-center my-4"><Skeleton /></div>
          <div className="text-center my-4"><Skeleton /></div>
        </>
        : teacherId === "0" || ApiDataResponseListLengthCheck(ApiDataListType.TEACHER) == 0 || !currentTeacher
          ? <div className="text-center"><Typography variant="h6">{t('NO_TEACHER_SELECTED')}</Typography></div>
          : <>
            <CollapsedSection sectTitle={t('TEACHER_PROFILE')}>
              <div className="grid grid-cols-4 gap-4">
                <TextField
                  value={currentTeacher.title}
                  label={t('TITLE')}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  disabled
                />
                <TextField
                  value={currentTeacher.username}
                  label={t('USERNAME')}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  disabled
                />
                <TextField
                  value={currentTeacher.firstName}
                  label={t('FIRST_NAME')}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  disabled
                />
                <TextField
                  value={currentTeacher.lastName}
                  label={t('LAST_NAME')}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  disabled
                />
                <TextField
                  value={currentTeacher.middleName}
                  label={t('MIDDLE_NAME')}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  disabled
                />
                <TextField
                  value={currentTeacher.birthDate.split('T')[0]}
                  label={t('BOD')}
                  type="date"
                  variant="outlined"
                  sx={{ width: '100%' }}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  value={currentTeacher.emailAddress}
                  label={t('EMAIL')}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  className="col-span-2"
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </CollapsedSection>
          </>}

      {/* Teacher Section */}
      {currentTeacher && <>
        <Heading>{t('STUDENT_LIST')}</Heading>
        <Box sx={ITEM_PERFECT_INLINED} style={{ width: 'fit-content' }} className="bg-white p-6 rounded-xl mb-4">
          <div className="mr-4">
            <FormControl fullWidth>
              <InputLabel>{t('YEAR')}</InputLabel>
              <Select
                label={t('YEAR')}
                sx={{ width: 300 }}
                value={yearDesc}
                onChange={onChangeYear}
                size="small"
              >
                {yearSelectOptions.map((year: number, index: number) => (
                  <MenuItem key={`year_select_${index}`} value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div>
            <Button
              size="large"
              variant="outlined"
              onClick={() => {
                onSearchStudentList(tabValue);
                setSearchKey("");
                setStudentId(0);
                setTraineeshipList(null);
              }}
            >
              {t('SEARCH')}
            </Button>
          </div>

          <RadioGroup row sx={{ marginLeft: 4 }}>
            <FormControlLabel
              value={0}
              control={<Radio onChange={handleRadioChange} checked={tabValue == TraineeListMode.ALL} />}
              label="Show List of All Trainees"
            />
            <FormControlLabel
              value={1}
              control={<Radio onChange={handleRadioChange} checked={tabValue == TraineeListMode.BY_GROUP} />}
              label="Show Group - Organisation - Trainees"
            />
          </RadioGroup>
        </Box>
      </>}

      {currentTeacher && <>
        {tabValue == TraineeListMode.ALL ? <VmTable
          loading={teacherStore.loadingTrainee}
          thead={["ID", t('NAME'), t('BOD'), t('EMAIL'), t('ACTION')]}
          page={page}
          paginationCount={teacherStore.allTraineeList.totalCount == 0 ? 1 : teacherStore.allTraineeList.totalCount / PAGE_SIZE}
          onChangePagination={(event, pageNumber) => {
            setPage(pageNumber);
            teacherStore.getTraineeListByTrainerIdAndYear(+teacherId, yearDesc, PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
          }}
          tbody={teacherStore.allTraineeList.data.map((trainee, index) => (
            <>
              <Box sx={{ marginY: 1 }} />
              <tr key={`year_level_list_${index}`}>
                <td className={TD_FIRST}>{index + 1}</td>
                <td className={TD_NORMAL}>{trainee.firstName + ' ' + trainee.lastName}</td>
                <td className={TD_NORMAL}>{trainee.birthDate}</td>
                <td className={TD_NORMAL}>{trainee.emailAddress}</td>
                <td className={TD_LAST}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      onRequestTraineeshipList(trainee.id)}}
                  >
                    {t('View Traineeships')}
                  </Button>
                </td>
              </tr>
              <Box sx={{ marginY: 1 }} />
            </>
          ))}
        />
          : <div className="grid grid-cols-4 gap-4">
            <div className={`max-h-96 overflow-auto ${teacherStore.loadingTrainee && 'col-span-4'}`}>
              {teacherStore.loadingTrainee ? <>
                <div className="text-center my-4"><Skeleton /></div>
                <div className="text-center my-4"><Skeleton /></div>
                <div className="text-center my-4"><Skeleton /></div>
              </> :
                teacherStore.traineeByGroupList.length > 0 &&
                teacherStore.traineeByGroupList.map((org: any, index: number) => (
                  <Box key={`by_group_select_${index}`}>
                    <button
                      type="button"
                      className={`${currentOrg == org.id ? INLINE_SELECTED_OPTION : INLINE_SELECT_OPTION}`}
                      onClick={() => {
                        setCurrentOrg(org.id);
                        setSearchKey("");
                      }}
                    >
                      {org.name}
                    </button>
                  </Box>
                ))}
            </div>
            <div className="col-span-3">
              {currentOrg != 0 && !teacherStore.loadingTrainee &&
                <VmTable
                  loading={teacherStore.loadingTrainee}
                  thead={["ID", t('NAME'), t('BOD'), t('EMAIL'), t('ACTION')]}
                  page={0}
                  tbody={teacherStore.traineeByGroupList.find(o => o.id == currentOrg)
                    .traineeList.map((trainee, index) => (
                      <>
                        <Box sx={{ marginY: 1 }} />
                        <tr key={`group_list_${index}`}>
                          <td className={TD_FIRST}>{index + 1}</td>
                          <td className={TD_NORMAL}>{trainee.firstName + ' ' + trainee.lastName}</td>
                          <td className={TD_NORMAL}>{trainee.birthDate}</td>
                          <td className={TD_NORMAL}>{trainee.emailAddress}</td>
                          <td className={TD_LAST}>
                            <Button
                              variant="outlined"
                              onClick={() => onRequestTraineeshipList(trainee.id)}
                            >
                              {t('View Traineeships')}
                            </Button>
                          </td>
                        </tr>
                        <Box sx={{ marginY: 1 }} />
                      </>
                    ))}
                />}

            </div>
          </div>}

        {studentId > 0 &&
          <>
            <Heading>
              {t('Traineeship List')}: {teacherStore.allTraineeList.data.find(s => s.id == studentId).firstName + " " +
                teacherStore.allTraineeList.data.find(s => s.id == studentId).lastName}
            </Heading>
            <VmTable
              loading={studentStore.loading}
              thead={["ID", t('DOMAIN') + " Code", t('DOMAIN'), t('TEACHER'), "Relevant Organisation", t('ACTION')]}
              page={0}
              tbody={traineeshipList &&
                traineeshipList.map((tShip: any, index: number) => (
                  <>
                    <Box sx={{ marginY: 1 }} />
                    <tr key={`trs_list_${index}`} style={{cursor: "pointer"}}>
                      <td className={TD_FIRST} onClick={() => onToggleTraineeshipFold(tShip.id)}>{index + 1}</td>
                      <td className={TD_NORMAL} onClick={() => onToggleTraineeshipFold(tShip.id)}>{tShip.qualification.code}</td>
                      <td className={TD_NORMAL} onClick={() => onToggleTraineeshipFold(tShip.id)}>{tShip.qualification.name}</td>
                      <td className={TD_NORMAL} onClick={() => onToggleTraineeshipFold(tShip.id)}>{tShip.trainer ? tShip.trainer.firstName + " " + tShip.trainer.lastName : tShip.trainerStatus}</td>
                      <td className={TD_NORMAL} onClick={() => onToggleTraineeshipFold(tShip.id)}>{tShip.organisation.name}</td>
                      <td className={TD_LAST}>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setTraineeshipId(tShip.id);
                            setCurrentTraineeship(tShip);
                            setSearchKey("");
                            studentStore.getVisitListByTraineeshipId(tShip.id);
                          }}
                        >
                          {t('VIEW_VISITS')}
                        </Button>
                      </td>
                    </tr>
                    <TraineeshipCard
                      traineeship={tShip}
                      fold={traineeshipFold}
                      colSpan={6}
                    />
                    <Box sx={{ marginY: 1 }} />
                  </>
                ))}
            />
          </>}


        {/* only student is selected */}
        {studentId > 0 && traineeshipId > 0 &&
          <>
            <Heading>
              {t('VISIT_LIST')}: {traineeshipList.find(s => s.id == traineeshipId).qualification.code}
            </Heading>
            <TextField
              value={searchKey}
              label={t('Seach Visit by Date')}
              variant="outlined"
              sx={{ width: 200, marginY: 2, background: 'white' }}
              onChange={(event) => setSearchKey(event.target.value)}
              placeholder="eg. 2022-01-01"
              size="small"
            />
            <Box sx={ITEM_PERFECT_INLINED} className="mb-2">
              <span className="bg-sky-400 text-sky-400 rounded-2xl text-white p-2 pt-1 mr-2" />
              <Typography>Trainer & Assesor Not Check In Yet</Typography>
              <span className="bg-orange-400 text-orange-400 rounded-2xl text-white p-2 pt-1 ml-4 mr-2" />
              <Typography> Check In By Trainer & Assesor</Typography>
              <span className="bg-green-400 text-green-400 rounded-2xl text-white p-2 pt-1 ml-4 mr-2" />
              <Typography> Check Out By Trainer & Assesor</Typography>
            </Box>
            <VmTable
              loading={studentStore.loadingVisit}
              thead={["ID", t('VISIT_NAME'), "Date", t('START_TIME'), t('END_TIME'), "Check In Time", "Check Out Time", "File Upload Status", "Check in " + t('STATUS')]}
              page={0}
              tbody={studentStore.visitListByTraineeship
                .filter(v => searchKey === "" ? v.startTime !== ""
                  : (v.startTime.includes(searchKey) || v.endTime.includes(searchKey))).map((visit, index) => (
                    <>
                      <Box sx={{ marginY: 1 }}/>
                      <tr key={`visit_${index}`} style={{cursor: "pointer"}}>
                        <td className={TD_FIRST} onClick={() => onToggleFold(visit.id)}>{index + 1}</td>
                        <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.name}</td>
                        <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.finalStartTime.split('T')[0]}</td>
                        <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.finalStartTime.split('T')[1]}</td>
                        <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.finalEndTime.split('T')[1]}</td>
                        <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.checkInTime ? visit.checkInTime.split('T')[1] : "-"}</td>
                        <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.checkOutTime ? visit.checkOutTime.split('T')[1] : "-"}</td>
                        <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)} title={visit.organisationVisitDocuments.length > 0 ? "Files Uploaded" : "No File Uploaded"}>
                          {visit.organisationVisitDocuments.length > 0 ?
                            <Box sx={ITEM_PERFECT_INLINED} className="text-green-500 text-3xl pl-8" textAlign={"center"}><FileDownloadDoneIcon fontSize="inherit" /></Box>
                            : <Box sx={ITEM_PERFECT_INLINED} className="text-red-500 text-3xl pl-8" textAlign={"center"}><FileDownloadOffIcon /></Box>}
                        </td>
                        <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>
                          <span className={`${visit.checkOutTime ? "bg-green-400" : visit.checkInTime ? "bg-orange-400" : "bg-sky-400"} rounded-2xl text-white p-2 pt-1`}>
                            <Typography variant="caption">{visit.checkOutTime ? "Checked Out" : visit.checkInTime ? t('CHECKED_IN_BY_TEACHER') : t('NOT_CHECK_IN_BY_TEACHER')}</Typography>
                          </span>
                        </td>
                        {/* <td className={TD_LAST}>
                          <Button
                            variant="outlined"
                            onClick={() => handleShowModal(visit.id)}
                          >
                            {t('VIEW')}
                          </Button>
                        </td> */}
                      </tr>

                      {/* folder */}
                    {/* <tr className={fold === visit.id ? "" : "hidden"}> */}
                        <VmVisitSlideDownCard
                          visit={visit}
                          visitChangeList={userStore.visitChangeList.data}
                          loadingVisitTable={userStore.loadingVisit}
                          fold={fold}
                          currentTraineeship={currentTraineeship}
                          colspan={10}
                        />
                      <Box sx={{ marginY: 1 }} />
                    </>
                  ))}
            />
          </>}
      </>}

      <VmModal
        openModal={showModal}
        onClose={() => {
          setShowModal(false);
          setCurrentVisit(null);
          setModalTabValue(0);
        }}
        width={600}
        title={t('VISIT_INFO')}
        showButton={false}
      >
        {currentVisit &&
          <>
            <Box display="flex" justifyContent="center" width="100%" marginBottom={2}>
              <Tabs
                value={modalTabValue}
                onChange={handleChangeModalTab}
                sx={{ marginTop: -4, textAlign: 'center', justifyContent: 'center' }}
              // TabIndicatorProps={{ style: { background: THEME_COLOR } }}
              >
                <Tab label={t('VISIT_INFO')} {...TabProps(0)} />
                <Tab label={t('UPLOADED_FILES')} {...TabProps(1)} />
              </Tabs>
            </Box>
            <Divider sx={{ marginBottom: 1 }} />
            <TabPanel value={modalTabValue} index={0} paddingValue={0}>
              <table style={{ width: '100%' }}>
                <thead><th /><th /></thead>
                <tbody>
                  <tr>
                    <td className="font-bold">{t('Location')}: </td>
                    <td className="text-right">{currentVisit.finalLocation.name}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('Student Name')}: </td>
                    <td className="text-right">{currentVisit.studentName}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('CREATE_TIME')}: </td>
                    <td className="text-right">{currentVisit.createTime.split('T')[0] + " " + currentVisit.createTime.split('T')[1]}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('UPDATED_TIME')}: </td>
                    <td className="text-right">{currentVisit.updateTime.split('T')[0] + " " + currentVisit.updateTime.split('T')[1]}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('START_TIME')}: </td>
                    <td className="text-right">{currentVisit.finalStartTime.split('T')[0] + " " + currentVisit.finalStartTime.split('T')[1]}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('END_TIME')}: </td>
                    <td className="text-right">{currentVisit.finalEndTime.split('T')[0] + " " + currentVisit.finalEndTime.split('T')[1]}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">Responsible Trainer Name: </td>
                    <td className="text-right">{currentVisit.responsibleTrainerName}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">Conduct Trainer Name: </td>
                    <td className="text-right">{currentVisit.conductTrainerName}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('DESCRIPTION')}: </td>
                  </tr>
                  <tr><td colSpan={2}>
                    <div className="my-2 mt-1">
                      <TextField
                        value={currentVisit.description}
                        rows={2}
                        sx={{ width: '100%' }}
                        disabled
                        multiline
                        size="small"
                      />
                    </div>
                  </td></tr>
                </tbody>
              </table>

              <div className="my-2 mt-1">
                <TextField
                  value={currentVisit.remark ? currentVisit.remark : t('NO_FEEDBACK_YET')}
                  rows={2}
                  sx={{ width: '100%' }}
                  disabled
                  multiline
                  size="small"
                />
              </div>

            </TabPanel>
            <TabPanel value={modalTabValue} index={1} paddingValue={0}>
              {currentVisit.organisationVisitDocuments.length > 0 ?
                <div className="grid grid-cols-5 gap-4">
                  {currentVisit.organisationVisitDocuments.map((doc: any) => (
                    <button className="border hover:border-theme hover-border-2"
                      onClick={() => {
                        if (doc.documentName.split('.')[1] !== "pdf") {
                          window.open(constructImgUrl(DocumentType.STUDENT_TRAINEESHIP_FILE, FileCategory.FORM_PERSONAL_ID, currentVisit.id, doc.physicalName));
                        } else {
                          groupStore.getUploadedFile(currentVisit.id, doc.documentName)
                            .then((res) => {
                              const file = new Blob([res], { type: "application/pdf" });
                              const fileURL = URL.createObjectURL(file);
                              window.open(fileURL);
                            })
                        }
                      }} disabled={groupStore.loadingFile}>
                      {doc.documentName.split('.')[1] !== "pdf" ?
                        <img src={constructImgUrl(DocumentType.STUDENT_TRAINEESHIP_FILE, FileCategory.FORM_PERSONAL_ID, currentVisit.id, doc.physicalName)} />
                        : groupStore.loadingFile ? <CircularProgress className="text-theme" />
                          : <>
                            <FilePresentIcon fontSize={"large"} />
                            <Typography variant="caption">PDF</Typography>
                          </>}
                    </button>
                  ))}
                </div>
                : <Typography textAlign="center">{t('NO_DOCUMENT_UPLOADED_IN_THIS_VISIT')}</Typography>}
            </TabPanel>
          </>}
      </VmModal>
    </ContentLayout>
  )
});

export default TeacherProfilePage;
